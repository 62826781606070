<script>
import { mapGetters } from "vuex";
import { AppMoney } from "../directives";
import AppOrderTotal from "./order-total";
import OrderModel from "../vuex/models/order";

export default {
  name: "AppOrderSummary",
  components: { AppOrderTotal },
  directives: {
    AppMoney,
  },
  props: {
    /**
     * @var {OrderModel}
     * */
    order: {
      type: OrderModel,
      default: new OrderModel(),
    },
    loading: Boolean,
  },

  methods: {
    /**
     * Verifica o valor informado é um acrescimo
     * @returns {Boolean}
     */
    isDiscount(value) {
      if (!value) {
        return false;
      }

      return value.indexOf("-") !== -1;
    },
  },

  computed: {
    ...mapGetters(["langs"]),

    /**
     * @returns {String} Total de produtos
     */
    products() {
      return this.order.calculator.products;
    },

    /**
     * @returns {String} Cupom de desconto
     */
    coupon() {
      return this.order.calculator.coupon;
    },

    /**
     * @returns {String} Label referente a forma de pagamento
     */
    paymentLabel() {
      if (this.isDiscount(this.payment)) {
        return this.langs.summary["discount-payment"];
      }

      return this.langs.summary["increase-payment"];
    },

    /**
     * @returns {String} Desconto ou acréscimo de pagamento
     */
    payment() {
      return this.order.calculator.payment;
    },

    /**
     * @returns {String} Impostos
     */
    taxes() {
      return this.order.calculator.taxes;
    },

    /**
     * @returns {String} Forma de envio
     */
    shippingName() {
      return this.order.shipping.name;
    },

    /**
     * @returns {String} Forma de envio
     */
    shipping() {
      return this.order.calculator.shipping;
    },

    /**
     * @returns {String} Total do pedido
     */
    total() {
      return this.order.calculator.total;
    },
  },
};
</script>

<template>
  <section class="app__order-summary">
    <header class="order-summary__header">
      <h3
        class="oder-status__title order-status__title--3"
        :class="{ app__loading: loading }"
      >
        {{ this.langs.summary["order-summary"] }}
      </h3>
    </header>
    <table class="app__table">
      <tbody>
        <tr class="app__table__row app__summary-products">
          <td class="app__table__cell">
            <span
              class="app__summary-products-label"
              :class="{ app__loading: loading }"
            >
              {{ this.langs.summary["products"] }}
            </span>
          </td>
          <td class="app__table__cell app__table__cell--right">
            <span
              class="app__summary-products-label"
              :class="{ app__loading: loading }"
            >
              {{ products }}
            </span>
          </td>
        </tr>

        <tr
          class="app__table__row app__summary-coupon-discount"
          v-show="coupon"
        >
          <td class="app__table__cell">
            <span
              class="app__summary-coupon-discount-label"
              :class="{ app__loading: loading }"
            >
              {{ this.langs.summary["discount"] }}
            </span>
          </td>
          <td class="app__table__cell app__table__cell--right">
            <span
              class="app__summary-coupon-discount-value"
              :class="{ app__loading: loading }"
            >
              {{ coupon }}
            </span>
          </td>
        </tr>

        <tr
          class="app__table__row app__summary-products-payment-discount"
          v-show="payment"
        >
          <td class="app__table__cell">
            <span
              class="app__summary-payment-discount-label"
              :class="{ app__loading: loading }"
            >
              {{ paymentLabel }}
            </span>
          </td>
          <td class="app__table__cell app__table__cell--right">
            <span
              class="app__summary-payment-discount-value"
              :class="{ app__loading: loading }"
            >
              {{ payment }}
            </span>
          </td>
        </tr>

        <tr class="app__table__row app__summary-products-taxes" v-show="taxes">
          <td class="app__table__cell">
            <span
              class="app__summary-payment-taxes"
              :class="{ app__loading: loading }"
            >
              {{ this.langs.summary["taxes"] }}
            </span>
          </td>
          <td class="app__table__cell app__table__cell--right">
            <span class="app__summary-taxes" :class="{ app__loading: loading }">
              {{ taxes }}
            </span>
          </td>
        </tr>

        <tr
          class="app__table__row app__summary-shipping"
          v-if="!this.order.hasOnlyVirtualProducts"
        >
          <td class="app__table__cell">
            <span
              class="app__summary-shipping-label"
              :class="{ app__loading: loading }"
            >
              {{ this.langs.summary["shipping-tax"] }}
              <small class="d-block">{{ shippingName }}</small>
            </span>
          </td>
          <td class="app__table__cell app__table__cell--right">
            <span
              class="app__summary-shipping-value"
              :class="{ app__loading: loading }"
            >
              {{ shipping }}
            </span>
          </td>
        </tr>

        <tr
          v-if="order.extensions.hasGiftCard()"
          class="app__table__row app__summary-gift-card"
        >
          <td class="app__table__cell">
            <span
              class="app__summary-shipping-label"
              :class="{ app__loading: loading }"
            >
              {{ this.langs.summary["gift-card"] }}
            </span>
          </td>
          <td class="app__table__cell app__table__cell--right">
            <span
              class="app__summary-shipping-value"
              :class="{ app__loading: loading }"
              v-app-money="order.extensions.getGiftCardTotal()"
            >
            </span>
          </td>
        </tr>

        <tr
          v-if="order.extensions.hasGiftWrap()"
          class="app__table__row app__summary-gift-wrap"
        >
          <td class="app__table__cell">
            <span
              class="app__summary-shipping-label"
              :class="{ app__loading: loading }"
            >
              {{ this.langs.summary["gift-package"] }}
            </span>
          </td>
          <td class="app__table__cell app__table__cell--right">
            <span
              class="app__summary-shipping-value"
              :class="{ app__loading: loading }"
              v-app-money="order.extensions.getGiftWrapTotal()"
            >
            </span>
          </td>
        </tr>

        <tr class="app__table__row app__summary-shipping-total">
          <td class="app__table__cell" colspan="2">
            <span
              class="app__summary-total-label"
              :class="{ app__loading: loading }"
            >
              <app-order-total :order="order"></app-order-total>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>
