<script>
import { mapGetters, mapState } from "vuex";
import { EmbedMap } from "tray-maps";

export default {
  name: "AppOrderPickupLocation",
  props: {
    loading: Boolean,
  },

  methods: {
    /**
     * Retorna o endereço de retirada
     * @returns {Object}
     */
    pickupLocation() {
      return this.list.pickupLocation;
    },

    /**
     * @return {string} Nome
     */
    name() {
      return this.pickupLocation().name;
    },

    /**
     * @return {string} Cidade
     */
    city() {
      return this.pickupLocation().address.city;
    },

    /**
     * @return @return {string} Bairro
     */
    neighborhood() {
      return this.pickupLocation().address.neighborhood;
    },

    /**
     * @return {string} Número
     */
    number() {
      return this.pickupLocation().address.number;
    },

    /**
     * @return {string} Estado
     */
    state() {
      return this.pickupLocation().address.state;
    },

    /**
     * @return {string} Rua
     */
    street() {
      return this.pickupLocation().address.street;
    },

    /**
     * @return {string} CEP
     */
    zipCode() {
      return this.pickupLocation().address.zipCode;
    },

    /**
     * @return {string} Complemento
     */
    complement() {
      return this.pickupLocation().address.complement;
    },

    /**
     * Retorna o telefone cadastrado
     * @return {string}
     */
    phoneNumber() {
      return this.pickupLocation().cellphone;
    },

    /**
     * Retorna o token para criação do mapa
     * @return {string}
     */
    tokenMap() {
      return this.pickupLocation().tokenMap;
    },

    toTitleCase(phrase) {
      return phrase
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },

    /**
     * Recupera a aplicação que exibirá o mapa
     * @return {undefined}
     */
    setEmbedMap() {
      const embedMap = new EmbedMap({
        containerId: "map",
        marker: `${this.street()}, ${this.number()}`,
        zoom: 16,
        size: "300x320",
        token: this.tokenMap(),
      });

      embedMap.draw();
    },

    /**
     * Copia o endereço para a área de transferência
     */
    copyAddress() {
      const textToCopy = document.getElementsByClassName(
        "app__order-pickup-location__street"
      )[0];
      this.selectText(textToCopy);
      document.execCommand("copy");
    },

    /**
     * Seleciona o texto a ser copiado
     */
    selectText(element) {
      let range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNodeContents(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
  },

  computed: {
    ...mapState("Orders", {
      list: (state) => state.order.list,
    }),
    ...mapGetters(["langs"]),
  },

  updated() {
    this.setEmbedMap();
  },
};
</script>

<template>
  <section class="app__order-pickup-location">
    <h3
      class="app__order-pickup-location__title order__pickup-location__title--3"
      :class="{ app__loading: loading }"
    >
      {{ this.langs.shipping["withdrawal-place"] }}
    </h3>

    <div
      class="app__order-pickup-location__address-map"
      :class="{ app__loading: loading }"
    >
      <div id="map"></div>
    </div>

    <span
      class="app__order-pickup-location__name"
      :class="{ app__loading: loading }"
    >
      {{ this.toTitleCase(name()) }}
    </span>

    <span
      class="app__order-pickup-location__street"
      :class="{ app__loading: loading }"
    >
      {{ `${this.toTitleCase(street())}, ${number()}` }}
      - {{ `${this.toTitleCase(city())} / ${state()}` }}
    </span>

    <button
      class="app__order-pickup-location__button app__button app__button--secondary-outline"
      :class="{ app__loading: loading }"
      @click="this.copyAddress"
    >
      {{ this.langs.shipping["copy-address"] }}
    </button>

    <span
      class="app__order-pickup-location__warning"
      :class="{ app__loading: loading }"
    >
      {{ this.langs.shipping["withdrawal-available"] }}
      {{ this.list.shipping.delivery_time }}
      {{ this.langs.shipping["after-approval"] }}
    </span>
  </section>
</template>
