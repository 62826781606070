/* eslint-disable quote-props */
const banks = {
  "001": {
    code: "001",
    name: "BANCO DO BRASIL S.A",
  },
  237: {
    code: "237",
    name: "BRADESCO S.A",
  },
  260: {
    code: "260",
    name: "NU PAGAMENTOS S.A",
  },
  290: {
    code: "290",
    name: "Pagseguro Internet S.A",
  },
  323: {
    code: "323",
    name: "Mercado Pago",
  },
  637: {
    code: "637",
    name: "BANCO SOFISA S.A",
  },
  "077": {
    code: "077",
    name: "BANCO INTER S.A",
  },
  341: {
    code: "341",
    name: "ITAÚ UNIBANCO S.A",
  },
  104: {
    code: "104",
    name: "CAIXA ECONÔMICA FEDERAL",
  },
  "033": {
    code: "033",
    name: "BANCO SANTANDER BRASIL S.A",
  },
  212: {
    code: "212",
    name: "BANCO ORIGINAL S.A",
  },
  756: {
    code: "756",
    name: "BANCOOB",
  },
  655: {
    code: "655",
    name: "BANCO VOTORANTIM S.A",
  },
  "041": {
    code: "041",
    name: "BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
  },
  389: {
    code: "389",
    name: "BANCO MERCANTIL DO BRASIL S.A",
  },
  422: {
    code: "422",
    name: "BANCO SAFRA S.A",
  },
  "070": {
    code: "070",
    name: "BANCO DE BRASÍLIA",
  },
  136: {
    code: "136",
    name: "UNICRED COOPERATIVA",
  },
  741: {
    code: "741",
    name: "BANCO RIBEIRÃO PRETO",
  },
  739: {
    code: "739",
    name: "BANCO CETELEM S.A",
  },
  743: {
    code: "743",
    name: "BANCO SEMEAR S.A",
  },
  100: {
    code: "100",
    name: "PLANNER CORRETORA DE VALORES S.A",
  },
  "096": {
    code: "096",
    name: "BANCO B3S.A",
  },
  747: {
    code: "747",
    name: "Banco RABOBANK INTERNACIONAL DO BRASIL S.A",
  },
  748: {
    code: "748",
    name: "SICREDI S.A",
  },
  752: {
    code: "752",
    name: "BNP PARIBAS BRASIL S.A",
  },
  "091": {
    code: "091",
    name: "UNICRED CENTRAL RS",
  },
  399: {
    code: "399",
    name: "KIRTON BANK",
  },
  108: {
    code: "108",
    name: "PORTOCRED S.A",
  },
  757: {
    code: "757",
    name: "BANCO KEB HANA DO BRASIL S.A",
  },
  102: {
    code: "102",
    name: "XP INVESTIMENTOS S.A",
  },
  "084": {
    code: "084",
    name: "UNIPRIME NORTE DO PARANÁ",
  },
  180: {
    code: "180",
    name: "CM CAPITAL MARKETS CCTVM LTDA",
  },
  "066": {
    code: "066",
    name: "BANCO MORGAN STANLEY S.A",
  },
  "015": {
    code: "015",
    name: "UBS BRASIL CCTVM S.A",
  },
  143: {
    code: "143",
    name: "TREVISO CC S.A",
  },
  "062": {
    code: "062",
    name: "HIPERCARD BM S.A",
  },
  "074": {
    code: "074",
    name: "BCO. J.SAFRA S.A",
  },
  "099": {
    code: "099",
    name: "UNIPRIME CENTRAL CCC LTDA",
  },
  "025": {
    code: "025",
    name: "BANCO ALFA S.A.",
  },
  "075": {
    code: "075",
    name: "BCO ABN AMRO S.A",
  },
  "040": {
    code: "040",
    name: "BANCO CARGILL S.A",
  },
  190: {
    code: "190",
    name: "SERVICOOP",
  },
  "063": {
    code: "063",
    name: "BANCO BRADESCARD",
  },
  191: {
    code: "191",
    name: "NOVA FUTURA CTVM LTDA",
  },
  "064": {
    code: "064",
    name: "GOLDMAN SACHS DO BRASIL BM S.A",
  },
  "097": {
    code: "097",
    name: "CCC NOROESTE BRASILEIRO LTDA",
  },
  "016": {
    code: "016",
    name: "CCM DESP TRÂNS SC E RS",
  },
  "012": {
    code: "012",
    name: "BANCO INBURSA",
  },
  "003": {
    code: "003",
    name: "BANCO DA AMAZONIA S.A",
  },
  "060": {
    code: "060",
    name: "CONFIDENCE CC S.A",
  },
  "037": {
    code: "037",
    name: "BANCO DO ESTADO DO PARÁ S.A",
  },
  159: {
    code: "159",
    name: "CASA CREDITO S.A",
  },
  172: {
    code: "172",
    name: "ALBATROSS CCV S.A",
  },
  "085": {
    code: "085",
    name: "COOP CENTRAL AILOS",
  },
  114: {
    code: "114",
    name: "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
  },
  "036": {
    code: "036",
    name: "BANCO BBI S.A",
  },
  394: {
    code: "394",
    name: "BANCO BRADESCO FINANCIAMENTOS S.A",
  },
  "004": {
    code: "004",
    name: "BANCO DO NORDESTE DO BRASIL S.A.",
  },
  320: {
    code: "320",
    name: "BANCO CCB BRASIL S.A",
  },
  189: {
    code: "189",
    name: "HS FINANCEIRA",
  },
  105: {
    code: "105",
    name: "LECCA CFI S.A",
  },
  "076": {
    code: "076",
    name: "BANCO KDB BRASIL S.A.",
  },
  "082": {
    code: "082",
    name: "BANCO TOPÁZIO S.A",
  },
  286: {
    code: "286",
    name: "CCR DE OURO",
  },
  "093": {
    code: "093",
    name: "PÓLOCRED SCMEPP LTDA",
  },
  273: {
    code: "273",
    name: "CCR DE SÃO MIGUEL DO OESTE",
  },
  157: {
    code: "157",
    name: "ICAP DO BRASIL CTVM LTDA",
  },
  183: {
    code: "183",
    name: "SOCRED S.A",
  },
  "014": {
    code: "014",
    name: "NATIXIS BRASIL S.A",
  },
  130: {
    code: "130",
    name: "CARUANA SCFI",
  },
  127: {
    code: "127",
    name: "CODEPE CVC S.A",
  },
  "079": {
    code: "079",
    name: "BANCO ORIGINAL DO AGRONEGÓCIO S.A",
  },
  "081": {
    code: "081",
    name: "BBN BANCO BRASILEIRO DE NEGOCIOS S.A",
  },
  118: {
    code: "118",
    name: "STANDARD CHARTERED BI S.A",
  },
  133: {
    code: "133",
    name: "CRESOL CONFEDERAÇÃO",
  },
  121: {
    code: "121",
    name: "BANCO AGIBANK S.A",
  },
  "083": {
    code: "083",
    name: "BANCO DA CHINA BRASIL S.A",
  },
  138: {
    code: "138",
    name: "GET MONEY CC LTDA",
  },
  "024": {
    code: "024",
    name: "BCO BANDEPE S.A",
  },
  "095": {
    code: "095",
    name: "BANCO CONFIDENCE DE CÂMBIO S.A",
  },
  "094": {
    code: "094",
    name: "BANCO FINAXIS",
  },
  276: {
    code: "276",
    name: "SENFF S.A",
  },
  137: {
    code: "137",
    name: "MULTIMONEY CC LTDA",
  },
  "092": {
    code: "092",
    name: "BRK S.A",
  },
  "047": {
    code: "047",
    name: "BANCO BCO DO ESTADO DE SERGIPE S.A",
  },
  144: {
    code: "144",
    name: "BEXS BANCO DE CAMBIO S.A.",
  },
  126: {
    code: "126",
    name: "BR PARTNERS BI",
  },
  301: {
    code: "301",
    name: "BPP INSTITUIÇÃO DE PAGAMENTOS S.A",
  },
  173: {
    code: "173",
    name: "BRL TRUST DTVM SA",
  },
  119: {
    code: "119",
    name: "BANCO WESTERN UNION",
  },
  254: {
    code: "254",
    name: "PARANA BANCO S.A",
  },
  268: {
    code: "268",
    name: "BARIGUI CH",
  },
  107: {
    code: "107",
    name: "BANCO BOCOM BBM S.A",
  },
  412: {
    code: "412",
    name: "BANCO CAPITAL S.A",
  },
  124: {
    code: "124",
    name: "BANCO WOORI BANK DO BRASIL S.A",
  },
  149: {
    code: "149",
    name: "FACTA S.A. CFI",
  },
  197: {
    code: "197",
    name: "STONE PAGAMENTOS S.A",
  },
  142: {
    code: "142",
    name: "BROKER BRASIL CC LTDA",
  },
  184: {
    code: "184",
    name: "BANCO ITAÚ BBA S.A",
  },
  634: {
    code: "634",
    name: "BANCO TRIANGULO S.A",
  },
  545: {
    code: "545",
    name: "SENSO CCVM S.A",
  },
  132: {
    code: "132",
    name: "ICBC DO BRASIL BM S.A",
  },
  298: {
    code: "298",
    name: "VIPS CC LTDA",
  },
  129: {
    code: "129",
    name: "UBS BRASIL BI S.A",
  },
  128: {
    code: "128",
    name: "MS BANK S.A BANCO DE CÂMBIO",
  },
  194: {
    code: "194",
    name: "PARMETAL DTVM LTDA",
  },
  310: {
    code: "310",
    name: "VORTX DTVM LTDA",
  },
  163: {
    code: "163",
    name: "COMMERZBANK BRASIL S.A BANCO MÚLTIPLO",
  },
  280: {
    code: "280",
    name: "AVISTA S.A",
  },
  146: {
    code: "146",
    name: "GUITTA CC LTDA",
  },
  279: {
    code: "279",
    name: "CCR DE PRIMAVERA DO LESTE",
  },
  182: {
    code: "182",
    name: "DACASA FINANCEIRA S/A",
  },
  278: {
    code: "278",
    name: "GENIAL INVESTIMENTOS CVM S.A",
  },
  271: {
    code: "271",
    name: "IB CCTVM LTDA",
  },
  "021": {
    code: "021",
    name: "BANCO BANESTES S.A",
  },
  246: {
    code: "246",
    name: "BANCO ABC BRASIL S.A",
  },
  751: {
    code: "751",
    name: "SCOTIABANK BRASIL",
  },
  208: {
    code: "208",
    name: "BANCO BTG PACTUAL S.A",
  },
  746: {
    code: "746",
    name: "BANCO MODAL S.A",
  },
  241: {
    code: "241",
    name: "BANCO CLASSICO S.A",
  },
  612: {
    code: "612",
    name: "BANCO GUANABARA S.A",
  },
  604: {
    code: "604",
    name: "BANCO INDUSTRIAL DO BRASIL S.A",
  },
  505: {
    code: "505",
    name: "BANCO CREDIT SUISSE S.A",
  },
  196: {
    code: "196",
    name: "BANCO FAIR CC S.A",
  },
  300: {
    code: "300",
    name: "BANCO LA NACION ARGENTINA",
  },
  477: {
    code: "477",
    name: "CITIBANK N.A",
  },
  266: {
    code: "266",
    name: "BANCO CEDULA S.A",
  },
  122: {
    code: "122",
    name: "BANCO BRADESCO BERJ S.A",
  },
  376: {
    code: "376",
    name: "BANCO J.P. MORGAN S.A",
  },
  473: {
    code: "473",
    name: "BANCO CAIXA GERAL BRASIL S.A",
  },
  745: {
    code: "745",
    name: "BANCO CITIBANK S.A",
  },
  120: {
    code: "120",
    name: "BANCO RODOBENS S.A",
  },
  265: {
    code: "265",
    name: "BANCO FATOR S.A",
  },
  "007": {
    code: "007",
    name: "BNDES",
  },
  188: {
    code: "188",
    name: "ATIVA S.A INVESTIMENTOS",
  },
  134: {
    code: "134",
    name: "BGC LIQUIDEZ DTVM LTDA",
  },
  641: {
    code: "641",
    name: "BANCO ALVORADA S.A",
  },
  "029": {
    code: "029",
    name: "BANCO ITAÚ CONSIGNADO S.A",
  },
  243: {
    code: "243",
    name: "BANCO MÁXIMA S.A",
  },
  "078": {
    code: "078",
    name: "HAITONG BI DO BRASIL S.A",
  },
  111: {
    code: "111",
    name: "BANCO OLIVEIRA TRUST DTVM S.A",
  },
  "017": {
    code: "017",
    name: "BNY MELLON BANCO S.A",
  },
  174: {
    code: "174",
    name: "PERNAMBUCANAS FINANC S.A",
  },
  495: {
    code: "495",
    name: "LA PROVINCIA BUENOS AIRES BANCO",
  },
  125: {
    code: "125",
    name: "BRASIL PLURAL S.A BANCO",
  },
  488: {
    code: "488",
    name: "JPMORGAN CHASE BANK",
  },
  "065": {
    code: "065",
    name: "BANCO ANDBANK S.A",
  },
  492: {
    code: "492",
    name: "ING BANK N.V",
  },
  250: {
    code: "250",
    name: "BANCO BCV",
  },
  145: {
    code: "145",
    name: "LEVYCAM CCV LTDA",
  },
  494: {
    code: "494",
    name: "BANCO REP ORIENTAL URUGUAY",
  },
  253: {
    code: "253",
    name: "BEXS CC S.A",
  },
  269: {
    code: "269",
    name: "HSBC BANCO DE INVESTIMENTO",
  },
  213: {
    code: "213",
    name: "BCO ARBI S.A",
  },
  139: {
    code: "139",
    name: "INTESA SANPAOLO BRASIL S.A",
  },
  "018": {
    code: "018",
    name: "BANCO TRICURY S.A",
  },
  630: {
    code: "630",
    name: "BANCO INTERCAP S.A",
  },
  224: {
    code: "224",
    name: "BANCO FIBRA S.A",
  },
  600: {
    code: "600",
    name: "BANCO LUSO BRASILEIRO S.A",
  },
  623: {
    code: "623",
    name: "BANCO PAN",
  },
  204: {
    code: "204",
    name: "BANCO BRADESCO CARTOES S.A",
  },
  479: {
    code: "479",
    name: "BANCO ITAUBANK S.A",
  },
  456: {
    code: "456",
    name: "BANCO MUFG BRASIL S.A",
  },
  464: {
    code: "464",
    name: "BANCO SUMITOMO MITSUI BRASIL S.A",
  },
  613: {
    code: "613",
    name: "OMNI BANCO S.A",
  },
  652: {
    code: "652",
    name: "ITAÚ UNIBANCO HOLDING BM S.A",
  },
  653: {
    code: "653",
    name: "BANCO INDUSVAL S.A",
  },
  "069": {
    code: "069",
    name: "BANCO CREFISA S.A",
  },
  370: {
    code: "370",
    name: "BANCO MIZUHO S.A",
  },
  249: {
    code: "249",
    name: "BANCO INVESTCRED UNIBANCO S.A",
  },
  318: {
    code: "318",
    name: "BANCO BMG S.A",
  },
  626: {
    code: "626",
    name: "BANCO FICSA S.A",
  },
  270: {
    code: "270",
    name: "SAGITUR CC LTDA",
  },
  366: {
    code: "366",
    name: "BANCO SOCIETE GENERALE BRASIL",
  },
  113: {
    code: "113",
    name: "MAGLIANO S.A",
  },
  131: {
    code: "131",
    name: "TULLETT PREBON BRASIL CVC LTDA",
  },
  "011": {
    code: "011",
    name: "C.SUISSE HEDGING-GRIFFO CV S.A",
  },
  611: {
    code: "611",
    name: "BANCO PAULISTA",
  },
  755: {
    code: "755",
    name: "BOFA MERRILL LYNCH BM S.A",
  },
  "089": {
    code: "089",
    name: "CCR REG MOGIANA",
  },
  643: {
    code: "643",
    name: "BANCO PINE S.A",
  },
  140: {
    code: "140",
    name: "EASYNVEST - TÍTULO CV S.A",
  },
  707: {
    code: "707",
    name: "BANCO DAYCOVAL S.A",
  },
  288: {
    code: "288",
    name: "CAROL DTVM LTDA",
  },
  101: {
    code: "101",
    name: "RENASCENCA DTVM LTDA",
  },
  487: {
    code: "487",
    name: "DEUTSCHE BANK S.A BANCO ALEMÃO",
  },
  233: {
    code: "233",
    name: "BANCO CIFRA",
  },
  177: {
    code: "177",
    name: "GUIDE",
  },
  633: {
    code: "633",
    name: "BANCO RENDIMENTO S.A",
  },
  218: {
    code: "218",
    name: "BANCO BS2S.A",
  },
  292: {
    code: "292",
    name: "BS2DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS",
  },
  169: {
    code: "169",
    name: "BANCO OLÉ BONSUCESSO CONSIGNADO S.A",
  },
  293: {
    code: "293",
    name: "LASTRO RDV DTVM LTDA",
  },
  285: {
    code: "285",
    name: "FRENTE CC LTDA",
  },
  "080": {
    code: "080",
    name: "B&T CC LTDA",
  },
  753: {
    code: "753",
    name: "NOVO BANCO CONTINENTAL S.A BM",
  },
  222: {
    code: "222",
    name: "BANCO CRÉDIT AGRICOLE BR S.A",
  },
  754: {
    code: "754",
    name: "BANCO SISTEMA",
  },
  "098": {
    code: "098",
    name: "CREDIALIANÇA CCR",
  },
  610: {
    code: "610",
    name: "BANCO VR S.A",
  },
  712: {
    code: "712",
    name: "BANCO OURINVEST S.A",
  },
  "010": {
    code: "010",
    name: "CREDICOAMO",
  },
  283: {
    code: "283",
    name: "RB CAPITAL INVESTIMENTOS DTVM LTDA",
  },
  217: {
    code: "217",
    name: "BANCO JOHN DEERE S.A",
  },
  117: {
    code: "117",
    name: "ADVANCED CC LTDA",
  },
  336: {
    code: "336",
    name: "BANCO C6S.A - C6BANK",
  },
  654: {
    code: "654",
    name: "BANCO A.J. RENNER S.A",
  },
};

export default class BankResolver {
  /**
   * Retorna o nome do banco.
   *
   * @return {string}
   * */
  static getName(code) {
    return banks[code] ? banks[code].name : `Banco ${code}`;
  }
}
