<!-- eslint-disable padded-blocks -->
<script>
import { mapGetters, mapState } from "vuex";
import AppOrderPaymentMethodBankAccounts from "./bank-accounts";
import AppOrderPaymentMethodIcon from "./icon-resolver";
import OrderModel from "../../vuex/models/order";

/**
 * @property {order} order
 * */
export default {
  name: "AppOrderTotal",

  components: {
    AppOrderPaymentMethodBankAccounts,
    AppOrderPaymentMethodIcon,
  },

  props: {
    order: {
      type: OrderModel,
      default: () => new OrderModel(),
    },
  },

  methods: {
    /**
     * Retorna o valor do jwtToken do localStorage
     * @return {string}
     */
    jwtToken() {
      return localStorage.getItem("jwtToken");
    },

    /**
     * Retorna as informações de pagamento do pix
     * @return {string}
     */
    isPix() {
      return this.hasPixCode
        ? this.getPixPaymentByModal()
        : this.getPixPaymentByUrl();
    },

    /**
     * Retorna as informações de pagamento do boleto
     * @return {string}
     */
    isBillet() {
      return `${this.getTotal} ${this.langs.product["billet-at-sight"]}`;
    },

    /**
     * Retorna as informações de pagamento do tipo depósito
     * @return {string}
     */
    isDeposit() {
      return `${this.getTotal} ${this.langs.product["deposit-at-sight"]}`;
    },

    /**
     * Retorna as informações de pagamento do tipo transferência
     * @return {string}
     */
    isTransfer() {
      return `${this.getTotal} ${this.langs.product["transfer-at-sight"]}`;
    },

    /**
     * Retorna as informações de pagamento do tipo cartão
     * @return {string}
     */
    isCard() {
      const name = `${
        this.order.payment_method.isDebitCard()
          ? `${this.langs.product["debit-card"]}`
          : `${this.langs.product["credit-card"]}`
      }`;
      return `${this.getTotal} ${this.langs.product.in} ${this.order.payment_method.installment}x ${this.langs.product.using} ${name}`;
    },

    /**
     * Retorna as informações de pagamento do tipo intermediário
     * @return {string}
     */
    isIntermediary() {
      const name = this.order.payment_method.getIntermediaryBrandName();
      return `${this.getTotal} ${this.langs.product.with} ${name}`;
    },

    /**
     * Retorna as informações sobre o pagamento
     * quando o QR code for inválido
     * @return {string}
     */
    getPixPaymentByUrl() {
      const paymentUrl = this.order.urls.payment;
      const inCashSummary = this.langs.summary["in-cash"];
      const payMessage = this.langs.summary.pay;

      return this.shouldShowPaymentButton
        ? `${this.getTotal} ${inCashSummary} Pix <br/> <a href="${paymentUrl}">${payMessage} Pix</a>`
        : `${this.getTotal} ${inCashSummary} Pix`;
    },

    /**
     * Retorna as informações sobre o pagamento do pix
     * quando o QR code for válido, com a opção de pagamento pelo modal
     * @return {string}
     */
    getPixPaymentByModal() {
      const inCashSummary = this.langs.summary["in-cash"];
      const payMessage = this.langs.summary.pay;
      const paymentPixModal = `<br/> <a href="" data-bs-toggle="modal" data-bs-target="#paymentPixModal">${payMessage} Pix</a>`;

      return this.shouldShowPaymentButton
        ? `${this.getTotal} ${inCashSummary} Pix ${paymentPixModal}`
        : `${this.getTotal} ${inCashSummary} Pix`;
    },
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),

    /**
     * Retorna a descrição do pagamento.
     *
     * @return {string|null}
     * */
    description() {
      /**
       * Mapeia todas as formas de pagamento definidas no modelo de PaymentMethodModel
       * tendo o mesmo nome do method que devolve a descrição formatada de cada forma
       */
      const mappingPaymentMethods = [
        "isBillet",
        "isDeposit",
        "isTransfer",
        "isCard",
        "isIntermediary",
        "isPix",
      ];

      const paymentMethod = mappingPaymentMethods.find((paymentMethod) =>
        this.order.payment_method[paymentMethod]()
      );

      return paymentMethod ? this[paymentMethod]() : null;
    },

    /**
     * Retona o valor total do pedido
     * @return {string}
     */
    getTotal() {
      return this.order.total;
    },

    /**
     * Verifica se há um QR code e informações
     * da transação para o pagamento do pix
     * @return {bool}
     */
    hasPixCode() {
      return this.order.urls.code !== "" && this.order.urls.transaction !== "";
    },

    /**
     * Verifica se o botão para efetuar o pagamento deve ser exibido
     * @return {bool}
     */
    shouldShowPaymentButton() {
      return !this.order.hasPayment() && this.order.status.type !== "canceled";
    },

    /**
     * Retona a URLformatada para metodos de
     * pagamento que precisam de confirmação
     * @return {string}
     */
    confirmationMethodLink() {
      let queries = `loja=${this.store_info.id}&pedido=${this.order.access_code}&status=${this.order.status.store}`;
      return `${this.store_info.uri.https}/loja/central_confirmar_pagamento.php?${queries}`;
    },

    /**
     * Verifica se o pagamento precisa de confirmação
     * @return {boolean}
     */
    needPaymentConfirmation() {
      return this.order.paymentMethodMessage.confirmation == "1";
    },

    /**
     * Verifica se deve ser exibido o botão de pagar boleto
     * @return {boolean}
     */
    showBilletPayment() {
      return (
        this.order.payment_method.isBillet() &&
        this.order.paymentLink &&
        !this.order.hasPayment()
      );
    },

    /**
     * Verifica se deve ser exibido o botão de pagar transferencia bancária
     * @return {boolean}
     */
    showTransferPayment() {
      return (
        this.order.payment_method.isTransfer() &&
        this.needPaymentConfirmation &&
        this.order.paymentLink
      );
    },
  },
};
</script>

<template>
  <div class="app__payment-info">
    <div v-if="!description">
      <div class="app__payment-info__total">
        <div class="col-auto mr-auto">
          {{ this.langs.product["order-total"] }}
        </div>
        <div class="col-auto">{{ order.total }}</div>
      </div>
    </div>

    <div v-else>
      <p>{{ this.langs.product["order-total"] }}</p>
      <div class="row app__payment-info__content">
        <div class="col-auto app__payment-info__content__icon">
          <app-order-payment-method-icon
            :paymentMethodName="this.order.payment_method.code"
          >
          </app-order-payment-method-icon>
        </div>
        <div class="col app__payment-info__content__description">
          <p class="mb-0" v-html="description" />

          <template v-if="this.order.status.isOpen()">
            <template v-if="this.order.payment_method.isCard()">
              <p v-if="order.hasPayment()">
                {{ order.payment.cardInfoFromNote }}
              </p>
            </template>

            <template v-else-if="this.order.payment_method.isDeposit()">
              <app-order-payment-method-bank-accounts :order="this.order">
                {{ this.langs.product["see-available-accounts"] }}
              </app-order-payment-method-bank-accounts>
            </template>

            <template v-else-if="showTransferPayment">
              <a
                :href="this.confirmationMethodLink"
                class="app__link"
                target="_blank"
              >
                {{ this.langs.product["perform-payment"] }}
              </a>
            </template>

            <template v-else-if="showBilletPayment">
              <a
                :href="this.order.paymentLink"
                class="app__link"
                target="_blank"
              >
                {{ this.langs.product["pay-billet"] }}
              </a>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
