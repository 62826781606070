// @tips https://css-tricks.com/power-custom-directives-vue/

/* eslint-disable no-param-reassign */

const FREE_PRICE = "Grátis";
const NO_PRICE = "R$ 0,00";

/**
 * Retorna o preço formatado
 * @param {object} el
 * @param {object} binding
 */
export function AppMoney(el, binding) {
  let price = binding.value;
  const textContent = el.innerHTML;

  if (price && !isNaN(price)) {
    price = (+price).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  if (price === NO_PRICE) {
    price = FREE_PRICE;
  }

  if (!textContent) {
    el.innerHTML = price;
  } else {
    el.innerHTML = textContent.replace(NO_PRICE, price);
  }
}
