<script>
import OrderModel from "../../vuex/models/order";
import PaymentOptionModel from "../../vuex/models/payment-option";

export default {
  name: "AppOrderPaymentMethodBankAccounts",

  data() {
    return {
      paymentOption: new PaymentOptionModel(),
    };
  },

  props: {
    order: {
      default: () => new OrderModel(),
    },
  },

  mounted() {
    if (this.order.payment_method.isDeposit() && !this.order.hasPayment()) {
      this.loadPaymentOption();
    }
  },

  methods: {
    /**
     * Carrega o meio de pagamento selecionado no pedido.
     *
     * @return {void}
     * */
    loadPaymentOption() {
      this.$http
        .get(
          `/orders/${this.order.hash}/payments-options/${this.order.payment_method.id}`
        )
        .then((response) => {
          this.paymentOption = new PaymentOptionModel(response.data);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },

    /**
     * Exibe/escode a modal.
     *
     * @return {void}
     * */
    togglePaymentInfoModal() {
      this.$refs["payment-method-deposit"].toggle();
    },
  },
};
</script>

<template>
  <div class="app__bank-accounts">
    <a
      v-if="paymentOption.hasBankAccounts()"
      class="app__bank-accounts__toggle-modal"
      v-on:click="togglePaymentInfoModal"
      data-bs-toggle="modal"
      data-bs-target="#main-modal"
    >
      <slot>Ver contas disponíveis</slot>
    </a>
    <div
      class="modal fade"
      id="main-modal"
      tabindex="-1"
      aria-labelledby="main-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="main-modal-label">
              Contas bancárias disponíveis
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <ul class="app__bank-accounts__list-group">
            <li
              v-for="account in paymentOption.bank_accounts"
              class="app__bank-accounts__list-group__item"
              :key="account.id"
            >
              <h3 class="app__bank-accounts__list-group__item__title">
                {{ account.bankName }}
              </h3>
              <p class="app__bank-accounts__list-group__item__text">
                Agência: {{ account.branch }}
              </p>
              <p class="app__bank-accounts__list-group__item__text">
                C/C: {{ account.account }}
              </p>
              <p class="app__bank-accounts__list-group__item__text">
                Títular: {{ account.favored }}
              </p>
              <p class="app__bank-accounts__list-group__item__text">
                CPF/CNPJ: {{ account.maskedDoc }}
              </p>
            </li>
          </ul>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
