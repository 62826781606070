<script>
import { mapState, mapGetters } from "vuex";
import AppSvg from "@/features/general/Svg";
import icon from "@/mixins/icon";

export default {
  name: "AppOrderVirtualDelivery",
  mixins: [icon],
  components: {
    AppSvg,
  },
  props: {
    loading: Boolean,
  },

  computed: {
    ...mapState("Orders", {
      list: (state) => state.order.list,
      model: (state) => state.order.model,
    }),

    ...mapGetters(["isMobile"]),

    /**
     * Retorna o email do usuario
     * @returns {string}
     */
    email() {
      return this.list.customer.email;
    },

    /**
     * Retorna o status do pedido
     * @returns {string}
     */
    status() {
      return this.list.status.generic;
    },

    /**
     * Retorna se o pedido possui pagamento
     * @returns {bool}
     */
    hasPayment() {
      return "payment" in this.list;
    },

    /**
     * Retorna o ícone
     * @return {array}
     */
    icon() {
      return this.hasDef(this.icons);
    },

    /**
     * Verifica se o pedido está no status 'Aguardando envio'
     * @return {bool}
     */
    waitingShipment() {
      return !this.hasPayment || this.list.shipping.date === "";
    },

    /**
     * Define o tipo da mensagem exibida
     * @return {string}
     */
    type() {
      if (this.waitingShipment) {
        return "paper-plane";
      }

      return "check-circle";
    },

    /**
     * Define a mensagem exibida
     * @return {string}
     */
    message() {
      if (this.waitingShipment) {
        return "Após a confirmação de pagamento, enviaremos instruções para o seu e-mail.";
      }

      return "O produto foi enviado para o e-mail cadastrado.";
    },
  },
};
</script>

<template>
  <section
    class="app__order-virtual-delivery"
    :class="{ app__loading: loading }"
  >
    <figure
      v-if="status !== 'cancelado'"
      class="app__order-virtual-delivery__icon"
      :class="{ app__loading: loading }"
    >
      <app-svg :name="type" class="app__icon"></app-svg>
    </figure>
    <p
      v-if="status !== 'cancelado'"
      class="app__order-virtual-delivery__message"
      :class="{
        app__loading: loading,
        mobile: isMobile,
        'app__order-virtual-delivery__message-waiting-shipment':
          type === 'paper-plane',
        'app__order-virtual-delivery__message-confirmed':
          type === 'check-circle',
      }"
    >
      {{ message }}
    </p>
    <h3
      class="app__order-virtual-delivery__title"
      :class="{ app__loading: loading }"
      v-if="status === 'cancelado'"
    >
      Endereço de entrega
    </h3>
    <span
      class="app__order-virtual-delivery_mail"
      :class="{
        app__loading: loading,
        app__mail__mobile: isMobile,
        app__mail__canceled: status === 'cancelado',
      }"
    >
      Email: {{ email }}
    </span>
  </section>
</template>
