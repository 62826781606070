<script>
import { mapGetters, mapState } from "vuex";
import AppSvg from "@/features/general/Svg";

export default {
  name: "AppOrderWithdraw",

  components: {
    AppSvg,
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapState("Orders", {
      loading: (state) => state.loading,
    }),
  },
};
</script>
<template>
  <section class="app__order-address">
    <div class="app__order-withdraw" :class="{ app__loading: loading }">
      <app-svg name="exclamation-circle" />
      <strong
        class="app__order-address__recipient"
        :class="{ app__loading: loading }"
      >
        {{ this.langs.shipping["order-picked-up-upon-presentation-photo"] }}
      </strong>
    </div>
  </section>
</template>
