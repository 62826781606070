import BaseModel from "@/store/models/base";

export default class PaymentOptionInstallmentModel extends BaseModel {
  /**
   * {@inheritDoc}
   * */
  getSchema() {
    return {
      value: 0,
      base_value: 0,
      installment: 0,
      interest: 0,
      interest_value: 0,
      discount_value: 0,
      increase_value: 0,
    };
  }
}
