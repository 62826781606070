/**
 * Funcões uteis para recuperar e definir informações do pedido
 */

/**
 * Monta url da troca de pagamento
 * @param {object} order
 * @param {object} storeInfo
 * @returns {string}
 */
export function getEditOrderUrl(order, storeInfo) {
  const storeUrl = storeInfo.uri.https;
  const storeId = storeInfo.id;
  const accessCode = order.access_code;

  const url = `${storeUrl}/checkout/recover?loja=${storeId}&pedido=${accessCode}&pag=1`;

  return url;
}
