<script>
import AppIcon from "@/features/general/Icons";
import AppProductInfo from "./product-rating/product-info.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { AppMoney } from "../directives";
import AppStars from "@/domains/Orders/components/product-rating/stars";
import moment from "moment";

export default {
  name: "AppOrderProducts",
  components: {
    AppIcon,
    AppProductInfo,
    AppStars,
  },
  directives: {
    AppMoney,
  },

  props: {
    /**
     * Lista as informações da loja
     */
    list: {
      type: Object,
      default() {
        return {
          products: [
            {
              rating: 2,
              name: "",
              brand: "",
              reference: "",
              model: "",
              warranty: "",
              included_items: "",
              availability: "",
              release_date: "",
              quantity: "",
              is_virtual: false,
              total_price: "",
              unit_price: "",
              url: {
                http: "",
                https: "",
              },
              is_giveaway: false,
            },
          ],
        };
      },
    },
    loading: Boolean,
    productDate: String,
  },

  beforeUnmount() {
    this.clearMessages();
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapState("Orders", {
      timeline: (state) => state.order.timeline,
      orderLoading: (state) => state.order.loading,
    }),
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),
  },

  methods: {
    ...mapActions("Orders", ["setProductRating", "clearMessages"]),

    /**
     * @return {Array} Produtos
     */
    products() {
      return this.list.products;
    },

    /**
     * @return {String} Unidade|Unidades
     */
    quantityLabel(quantity) {
      return quantity > 1
        ? `${this.langs.product.units}`
        : `${this.langs.product.unit}`;
    },

    /**
     * Define qual texto aparecerá em baixo das estrelas de acordo com o status do produto.
     *
     * Status 0 = "Editar avaliação"
     *
     * Status 1 = "Ver avaliação"
     *
     * Se o produto não possui uma avaliação o texto que deve aparecer é: "Avaliar produto"
     *
     * @param {object} product Informações do produto
     * @returns {string}
     */
    ratingText(product) {
      const ProductComment = product?.ProductComment;
      if (ProductComment?.rating) {
        return {
          0: this.langs.product["edit-review"],
          1: this.langs.product["view-review"],
        }[ProductComment.status];
      }
      return this.langs.product["rate-product"];
    },

    /**
     * Caso tenha hash, redireciona para tela de edição ou vizualização do comentário.
     * Se não, redireciona para a tela de avaliação
     *
     * @param {object} product Informações do produto
     * @param {int} commentHash Hash da avaliação do produto
     * @return {undefined}
     */
    redirectToRating(product, commentHash) {
      this.setProductRating(product);
      this.$router.push(
        `/orders/rating/${this.$route.params.id}/${
          commentHash ? commentHash : "new"
        }`
      );
    },

    hasStoreSettingEnabled(setting) {
      return (
        this.store_info.settings.store[setting] !== "0" &&
        this.store_info.settings.store[setting] !== undefined
      );
    },

    /**
     * Define se as estrelas de avaliação devem ser exibidas baseado nas seguintes regras:
     *
     * - A configuração de `product_review` está ativa na loja
     * - O pedido está com o status Finalizado
     * - O pedido tem menos de 90 dias ou está avaliado
     *
     * @param {object} ProductComment
     * @returns {boolean}
     */
    shouldShowStar({ ProductComment }) {
      const { isOldOrder } = this.dateDiffOrder();

      const productNotOldOrRated = isOldOrder || !!ProductComment;

      return (
        this.hasStoreSettingEnabled("product_review") &&
        this.timeline.steps.at(-1).current &&
        productNotOldOrRated
      );
    },

    /**
     * Determina se o pedido tem mais de 90 dias desde que foi efetuado
     * @returns {boolean}
     */
    dateDiffOrder() {
      moment.locale("pt-br");
      const now = moment(Date.now());
      const date = this.productDate.split(" ")[0].replaceAll("/", "-");
      const parsedDate = moment(date, "DD/MM/YYYY");
      const dateDiff = now.diff(parsedDate, "days");
      return { isOldOrder: dateDiff < 90 };
    },

    /**
     * Retorna o preço e o texto auxiliar
     *
     * @TODO substituir o texto "cada" pela lang de unidade de medida
     *
     * @param {string} price
     * @return {string}
     */
    unitPriceText(price) {
      return `(${price} cada)`;
    },

    /**
     * Exibe o valor da unidade apenas se houver mais de um produto no pedido
     *
     * @param {string} unity
     * @return {boolean}
     */
    showUnitPrice(unity) {
      return unity > 1;
    },

    /**
     * Retorna a tag html que será usada no componente
     *
     * @param {string} url
     * @return {string}
     */
    hasLinkComponent(url) {
      return url ? "a" : "div";
    },
  },
};
</script>

<template>
  <section class="app__order-products">
    <header class="app__order-products__header">
      <h3
        class="app__order-products__title order-status__title--3"
        :class="{ app__loading: orderLoading }"
      >
        {{ this.langs.product["products-bought"] }}
      </h3>
    </header>
    <!-- @TODO Limitar a exibição e adicionar um botão para exibir o restante | MOBILE ? -->
    <article v-for="product in products()" :key="product.id">
      <section
        class="app__order-products__product app__order-products__product__mobile"
      >
        <div
          class="app__order-products__product__mobile__container-stars"
          :class="{ app__loading__stars: orderLoading }"
          @click="this.redirectToRating(product, product.ProductComment?.hash)"
          v-show="shouldShowStar(product)"
        >
          <a :class="{ app__loading: orderLoading }">
            {{ this.ratingText(product) }}
          </a>
          <app-stars
            :loading="orderLoading"
            :readOnly="true"
            :rating="parseInt(product.ProductComment?.rating)"
            :starSize="16"
          />
        </div>
        <div class="app__order-products__product-info">
          <figure
            class="app__order-products__product-image"
            :class="{ app__loading: orderLoading }"
          >
            <component
              :is="this.hasLinkComponent(product.url.https)"
              :href="product.url.https"
              :title="`Ir para ${product.name}`"
              target="_blank"
              class="app__link"
            >
              <img
                v-if="product.ProductImage?.[0]?.thumbs?.['90']?.https"
                :src="product.ProductImage[0].thumbs['90'].https"
                :alt="product.name"
                class="app__image-responsive"
              />
              <app-icon v-else name="eye-slash" />
            </component>
          </figure>
          <app-product-info :product="product" :loading="orderLoading" />
        </div>
        <section class="product-description">
          <div class="app__order-products__product-quantity-container">
            <span
              class="app__order-products__product-quantity"
              :class="{ app__loading: orderLoading }"
            >
              {{ product.quantity }}
            </span>
            <span
              class="app__order-products__product-quantity-label"
              :class="{ app__loading: orderLoading }"
            >
              {{ quantityLabel(product.quantity) }}
            </span>
          </div>
          <div class="app__order-products__product-total-container">
            <span
              class="app__order-products__product-total"
              :class="{ app__loading: orderLoading }"
              v-app-money="product.total_price"
            >
            </span>
            <span
              class="app__order-products__product-total-unit"
              v-show="product.quantity > 1"
              :class="{ app__loading: orderLoading }"
              v-app-money="product.unit_price"
            >
              {{ this.unitPriceText(product.unit_price) }}
            </span>
            <span class="app__order-products__product-tags">
              <span
                class="app__order-products__virtual-tag badge bg-primary"
                :class="{ app__loading: orderLoading }"
                v-show="product.is_virtual"
              >
                {{ langs.product["virtual-product"] }}
              </span>
            </span>
            <span
              class="app__order-products__product-tags"
              v-show="this.showUnitPrice(product.quantity)"
            >
              <span
                class="app__order-products__giveaway-tag badge bg-primary"
                :class="{ app__loading: orderLoading }"
                v-show="product.is_giveaway"
              >
                {{ langs.product["gift"] }}
              </span>
            </span>
          </div>
          <div
            class="app__order-products__container-stars"
            :class="{ app__loading__stars: orderLoading }"
            @click="
              this.redirectToRating(product, product.ProductComment?.hash)
            "
            v-if="shouldShowStar(product)"
          >
            <app-stars
              :loading="orderLoading"
              :readOnly="true"
              :rating="parseInt(product.ProductComment?.rating)"
              :starSize="16"
            />
            <a :class="{ app__loading: orderLoading }">
              {{ this.ratingText(product) }}
            </a>
          </div>
        </section>
      </section>
    </article>
  </section>
</template>
