<script>
import { mapActions, mapGetters, mapState } from "vuex";
import AppTooltip from "@/features/bootstrap/Tooltip";
import AppOrderStatus from "./status";
import AppOrderAddress from "./address";
import AppOrderProducts from "./products";
import AppOrderPickupLocation from "./pickup-location";
import AppOrderVirtualDelivery from "./virtual-delivery";
import AppOrderSummary from "./summary";
import AppOrderWithdraw from "./withdraw.vue";
import { getEditOrderUrl } from "../services/order";

/**
 * @property {OrderModel} order
 * */
export default {
  name: "AppOrder",
  components: {
    AppOrderStatus,
    AppOrderAddress,
    AppOrderPickupLocation,
    AppOrderVirtualDelivery,
    AppOrderProducts,
    AppOrderSummary,
    AppTooltip,
    AppOrderWithdraw,
  },

  mounted() {
    this.fetch();
  },

  methods: {
    ...mapActions("Orders", ["setOrderLoading", "setOrderData"]),

    fetch() {
      this.setOrderLoading({ loading: true });
      const orderPath = `/orders/${this.$route.params.id}`;
      this.$http
        .get(orderPath)
        .then((response) => {
          this.setOrderData({
            list: response.data,
          });

          this.setTitle();
          this.setOrderLoading({ loading: false });
        })
        .catch((error) => {
          const orderNotFound = error.status === 404;
          return orderNotFound
            ? this.$router.push({ name: "orders.list" })
            : error;
        });
    },

    /**
     * Coloca o id do pedido no titulo da pagina
     */
    setTitle() {
      document.title += ` ${this.list.id}`;
    },

    /**
     * Código destinado a recuperação do feedback em pedidos com Retira Fácil
     */
    hotjarTracking() {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 1260929, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    },
  },

  computed: {
    ...mapState("Orders", {
      list: (state) => state.order.model,
      loading: (state) => state.order.loading,
      timeline: (state) => state.order.timeline,
    }),

    ...mapState({
      storeInfo: (state) => state.store_info.list,
    }),

    ...mapGetters(["langs"]),

    /**
     * Retorna a lista de produtos.
     *
     * @returns {Array}
     */
    productsList() {
      return {
        products: this.list.products,
      };
    },

    /**
     * Recupera url da troca de pagamento
     *
     * @returns {string}
     */
    changePaymentUrl() {
      return getEditOrderUrl(this.list, this.storeInfo);
    },

    /**
     * Verifica se já existem dados fiscais
     *
     * @returns {bool}
     */
    hasInvoiceData() {
      return this.list.invoice.link !== "";
    },

    /**
     * Verifica se o pedido é do tipo 'retirada'
     * @returns {bool}
     */
    isPickup() {
      return this.list.pickupLocation.shippingId !== null;
    },

    /**
     * Verifica se o pedido tem o frete "A retirar"
     * @returns {Boolean} Se o pedido é para retirada na loja
     */
    isWithdraw() {
      const isWithdrawShipping = !!this.list.shipping.withdraw;
      return isWithdrawShipping;
    },

    /**
     * Valida se o pedido possui entrega padrão
     * @returns {bool}
     */
    isRegularDelivery() {
      return !this.isPickup && !this.list.hasOnlyVirtualProducts;
    },
  },
};
</script>

<template>
  <section class="app__order">
    <article class="app__order-wrap">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <app-order-status />
          <app-order-products
            :list="productsList"
            :productDate="this.list.date"
            :loading="loading"
          ></app-order-products>
        </div>
      </div>
    </article>

    <div class="app__order-footer">
      <div class="row">
        <div class="col-12 col-md-6 d-flex">
          <app-order-withdraw class="app__order-wrap" v-if="isWithdraw" />
          <app-order-address
            class="app__order-wrap"
            v-else-if="isRegularDelivery"
          />
          <app-order-pickup-location
            :loading="loading"
            class="app__order-wrap app__order__pickup-location"
            v-if="isPickup"
          >
          </app-order-pickup-location>
          <app-order-virtual-delivery
            :loading="loading"
            class="app__order-wrap app__order__virtual-delivery"
            v-if="this.list.hasOnlyVirtualProducts"
          >
          </app-order-virtual-delivery>
        </div>
        <div class="col-12 col-md-6">
          <div class="app__order-wrap">
            <app-order-summary :order="this.list" :loading="loading">
            </app-order-summary>
            <div
              class="app__centralizes"
              v-if="this.list.status.allow_edit_order"
            >
              <hr class="app__separator" />
              <app-tooltip
                customClass="delivery-tooltip"
                v-if="!hasInvoiceData"
                placement="bottom"
                :tooltipMessage="
                  this.langs.order['cannot-change-payment-method']
                "
              >
                <button
                  id="app-invoice"
                  class="app__button app__button--secondary-outline app__button--no-margin app__button--disabled"
                >
                  {{ this.langs.order["change-payment-method"] }}
                </button>
              </app-tooltip>
              <a
                v-else
                class="app__button app__button--primary-outline app__button--no-margin"
                :class="{ app__loading: loading }"
                :href="changePaymentUrl"
              >
                {{ this.langs.order["change-payment-method"] }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.isPickup">
        <!-- Hotjar Tracking Code for Central -->
        {{ this.hotjarTracking() }}
      </div>
    </div>
  </section>
</template>
